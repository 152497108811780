import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body p-0" }
const _hoisted_2 = { class: "d-flex flex-stack card-p flex-grow-1" }
const _hoisted_3 = { class: "symbol symbol-50px me-2" }
const _hoisted_4 = { class: "symbol-label" }
const _hoisted_5 = { class: "d-flex flex-column text-end" }
const _hoisted_6 = { class: "text-dark fw-bolder fs-2" }
const _hoisted_7 = { class: "text-muted fw-bold mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createElementVNode("span", {
              class: _normalizeClass([`svg-icon-${_ctx.color}`, "svg-icon svg-icon-2x"])
            }, [
              _createVNode(_component_inline_svg, { src: _ctx.svgIcon }, null, 8, ["src"])
            ], 2)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.change), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.description), 1)
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "statistics-widget-3-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.height,
        type: "area"
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}